import firebase from "firebase";
import moment from "moment";

import { getSignedInUser } from "../../Auth/services/user";

export const createSurveyDocument = async ({ data }) => {
	const { userData, healthSurveys } = getSignedInUser();

	let { toast, documents, modal, ...surveyDocument } = data;

	surveyDocument = {
		created: new Date(),
		validUntil: new Date(moment().add(3, "M").toISOString()),
		subdomain: process.env.GATSBY_WEBSITE_URL,
		...surveyDocument,
	};

	try {
		await firebase
			.firestore()
			.collection("users")
			.doc(userData.id)
			.collection("healthSurveys")
			.add({ ...surveyDocument });

		sessionStorage.setItem(
			"healthSurveys",
			JSON.stringify([...healthSurveys, surveyDocument])
		);

		//TODO: add update airtable here
	} catch (error) {
		console.log({ error });
	}
};
